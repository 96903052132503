import React from "react";
import ViewComfyRoundedIcon from "@mui/icons-material/ViewComfyRounded.js";

function ChannelHeadingResetLayoutButton({ resetLayout }) {
  return (
    <div className="channel-heading__action-button">
      <div className="channel-heading__layout-reset-button">
        <button
          className="btn btn--icon"
          onClick={() => {
            resetLayout();
            document.activeElement.blur();
          }}
          aria-label="Reset layout"
          data-balloon-pos="right"
        >
          <ViewComfyRoundedIcon size={28} />
        </button>
      </div>
    </div>
  );
}

export default ChannelHeadingResetLayoutButton;
