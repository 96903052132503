import React from "react";
import {
  getPanelGroupElement,
  getResizeHandleElement,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import PanelResizeButton from "./panel-resize-button";

const ResizableLayout = React.forwardRef(({ children }, ref) => {
  const [isResizing, setResizing] = React.useState(false);
  const [disabledHandles, setDisabled] = React.useState({});
  const [collapsedPanels, setCollapsed] = React.useState({});
  const [minSizesInRatios, setMinSizes] = React.useState({});

  React.useImperativeHandle(ref, () => ({
    resetLayout: resetPanels,
  }));

  const leftPanelRef = React.useRef();
  const centerPanelRef = React.useRef();
  const rightPanelRef = React.useRef();
  const upperPanelRef = React.useRef();
  const lowerPanelRef = React.useRef();
  const rightUpperPanelRef = React.useRef();
  const rightMiddlePanelRef = React.useRef();
  const rightLowerPanelRef = React.useRef();
  const rightLowerUpperPanelRef = React.useRef();

  const elementRefs = React.useRef();

  React.useEffect(() => {
    const leftVerticalResizeElement = getResizeHandleElement("left-resize");
    const rightVerticalResizeElement = getResizeHandleElement("right-resize");
    const bottomHorizontalResizeElement = getResizeHandleElement("bottom-resize");
    const rightUpperHorizontalResizeElement = getResizeHandleElement("rightUpper-resize");
    const rightLowerHorizontalResizeElement = getResizeHandleElement("rightLower-resize");
    const rightLowerUpperHorizontalResizeElement = getResizeHandleElement("rightLowerUpper-resize");
    const rightMiddleHorizontalResizeElement = getResizeHandleElement("rightMiddle-resize");

    const mainPanelGroupElement = getPanelGroupElement("main-group");
    const innerPanelGroupElement = getPanelGroupElement("upper-group");
    const rightPanelGroupElement = getPanelGroupElement("right-group");

    elementRefs.current = {
      mainPanelGroupElement,
      innerPanelGroupElement,
      rightPanelGroupElement,
      bottomHorizontalResizeElement,
      leftVerticalResizeElement,
      rightVerticalResizeElement,
      rightUpperHorizontalResizeElement,
      rightMiddleHorizontalResizeElement,
      rightLowerUpperHorizontalResizeElement,
      rightLowerHorizontalResizeElement,
    };
  }, []);

  const pageLayout = React.useMemo(
    () => ({
      upper: {
        id: "upper",
        defaultSize: 85,
        minSize: 100,
        ref: upperPanelRef,
        sectionLabel: "Scheduler",
        className: "scheduler-layout__content",
        isCollapsed: collapsedPanels.upper,
      },
      lower: {
        id: "lower",
        defaultSize: 15,
        minSize: 135,
        ref: lowerPanelRef,
        children: children[6],
        sectionLabel: "Timeline",
        className: "scheduler-layout__bottom",
        isCollapsed: collapsedPanels.lower,
      },
      left: {
        id: "left",
        defaultSize: 20,
        minSize: 140,
        ref: leftPanelRef,
        children: children[0],
        sectionLabel: "Content List",
        className: "scheduler-layout__left",
        isCollapsed: collapsedPanels.left,
      },
      center: {
        id: "center",
        defaultSize: 55,
        minSize: 320,
        ref: centerPanelRef,
        children: children[1],
        sectionLabel: "Scheduler",
        className: "scheduler-layout__center",
        isCollapsed: collapsedPanels.center,
      },
      right: {
        id: "right",
        defaultSize: 25,
        minSize: 240,
        ref: rightPanelRef,
        sectionLabel: "Meta",
        className: "scheduler-layout__right",
        isCollapsed: collapsedPanels.right,
      },
      rightUpper: {
        id: "rightUpper",
        defaultSize: 30,
        minSize: 20,
        ref: rightUpperPanelRef,
        children: children[2],
        sectionLabel: "Playback",
        className: "scheduler-layout__player-container",
        isCollapsed: collapsedPanels.rightUpper,
      },
      rightMiddle: {
        id: "rightMiddle",
        defaultSize: 10,
        minSize: 40,
        ref: rightMiddlePanelRef,
        children: children[3],
        sectionLabel: "Schedule Information",
        isCollapsed: collapsedPanels.rightMiddle,
      },
      rightLowerUpper: {
        id: "rightLowerUpper",
        defaultSize: 30,
        minSize: 130,
        ref: rightLowerUpperPanelRef,
        children: children[4],
        sectionLabel: "Program Information",
        isCollapsed: collapsedPanels.rightLowerUpper,
      },
      rightLower: {
        id: "rightLower",
        defaultSize: 30,
        minSize: 200,
        ref: rightLowerPanelRef,
        children: children[5],
        sectionLabel: "Program Player",
        className: "scheduler-layout__player-container",
        isCollapsed: collapsedPanels.rightLower,
      },
    }),
    [
      children,
      collapsedPanels.center,
      collapsedPanels.left,
      collapsedPanels.lower,
      collapsedPanels.right,
      collapsedPanels.rightLower,
      collapsedPanels.rightLowerUpper,
      collapsedPanels.rightMiddle,
      collapsedPanels.rightUpper,
      collapsedPanels.upper,
    ],
  );

  React.useLayoutEffect(() => {
    let mainGroupObserver = null;
    let innerGroupObserver = null;
    let rightGroupObserver = null;

    if (elementRefs.current) {
      const mainResizeHandle = elementRefs.current.bottomHorizontalResizeElement;
      const innerGroupResizeHandles = [
        elementRefs.current.leftVerticalResizeElement,
        elementRefs.current.rightVerticalResizeElement,
      ];
      const rightGroupResizeHandles = [
        elementRefs.current.rightUpperHorizontalResizeElement,
        elementRefs.current.rightLowerUpperHorizontalResizeElement,
      ];

      mainGroupObserver = new ResizeObserver(() => {
        let mainGroupHeight = elementRefs.current.mainPanelGroupElement.getBoundingClientRect().height;
        mainGroupHeight -= mainResizeHandle.getBoundingClientRect().height;

        setMinSizes((prev) => ({
          ...prev,
          upper: (pageLayout.upper.minSize / mainGroupHeight) * 100,
          lower: (pageLayout.lower.minSize / mainGroupHeight) * 100,
        }));
      });

      innerGroupObserver = new ResizeObserver(() => {
        let innerGroupWidth = elementRefs.current.innerPanelGroupElement.getBoundingClientRect().width;

        innerGroupResizeHandles.forEach((resizeHandle) => {
          innerGroupWidth -= resizeHandle.getBoundingClientRect().width;
        });

        setMinSizes((prev) => ({
          ...prev,
          left: (pageLayout.left.minSize / innerGroupWidth) * 100,
          center: (pageLayout.center.minSize / innerGroupWidth) * 100,
          right: (pageLayout.right.minSize / innerGroupWidth) * 100,
        }));
      });

      rightGroupObserver = new ResizeObserver(() => {
        let rightPanelHeight = elementRefs.current.rightPanelGroupElement.getBoundingClientRect().height;
        let playerUpperHeight = elementRefs.current.rightPanelGroupElement
          .querySelector("#rightUpper video")
          ?.getBoundingClientRect().height;

        let playerLowerHeight =
          elementRefs.current.rightPanelGroupElement.querySelector("#rightLower video")?.getBoundingClientRect()
            .height ?? pageLayout.rightLower.minSize;

        rightGroupResizeHandles.forEach((resizeHandle) => {
          rightPanelHeight -= resizeHandle.getBoundingClientRect().height;
        });

        setMinSizes((prev) => ({
          ...prev,
          rightUpper: (Math.max(playerUpperHeight, pageLayout.rightUpper.minSize) / rightPanelHeight) * 100,
          rightMiddle: (pageLayout.rightMiddle.minSize / rightPanelHeight) * 100,
          rightLowerUpper: (pageLayout.rightLowerUpper.minSize / rightPanelHeight) * 100,
          rightLower: (Math.max(playerLowerHeight, pageLayout.rightLower.minSize) / rightPanelHeight) * 100,
        }));
      });

      mainGroupObserver.observe(elementRefs.current.mainPanelGroupElement);
      mainGroupObserver.observe(elementRefs.current.bottomHorizontalResizeElement);

      innerGroupObserver.observe(elementRefs.current.innerPanelGroupElement);
      innerGroupResizeHandles.forEach((resizeHandle) => {
        innerGroupObserver.observe(resizeHandle);
      });

      rightGroupObserver.observe(elementRefs.current.rightPanelGroupElement);
      rightGroupResizeHandles.forEach((resizeHandle) => {
        rightGroupObserver.observe(resizeHandle);
      });
    }

    return () => {
      if (mainGroupObserver) {
        mainGroupObserver.disconnect();
      }

      if (innerGroupObserver) {
        innerGroupObserver.disconnect();
      }

      if (rightGroupObserver) {
        rightGroupObserver.disconnect();
      }
    };
  }, [pageLayout]);

  function clickHandler(primary) {
    if (pageLayout[primary].isCollapsed) {
      expand(primary);
    } else {
      collapse(primary);
    }

    updateCollapsed();
    updateHandleDisables();
  }

  function collapse(key) {
    switch (key) {
      // outer sections
      case pageLayout.upper.id:
        collapseSection(pageLayout.upper, [pageLayout.upper, pageLayout.lower]);
        break;
      case pageLayout.lower.id:
        collapseSection(pageLayout.lower, [pageLayout.upper, pageLayout.lower]);
        break;

      //  inner sections
      case pageLayout.left.id:
        collapseSection(pageLayout.left, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;
      case pageLayout.center.id:
        collapseSection(pageLayout.center, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;
      case pageLayout.right.id:
        collapseSection(pageLayout.right, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;

      // inner right sections
      case pageLayout.rightUpper.id:
        collapseSection(pageLayout.rightUpper, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightMiddle.id:
        collapseSection(pageLayout.rightMiddle, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightLowerUpper.id:
        collapseSection(pageLayout.rightLowerUpper, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightLower.id:
        collapseSection(pageLayout.rightLower, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      default:
        // do nothing
        break;
    }
  }

  function expand(key) {
    switch (key) {
      //  outer sections
      case pageLayout.upper.id:
        restoreSection(pageLayout.upper, [pageLayout.upper, pageLayout.lower]);
        break;
      case pageLayout.lower.id:
        restoreSection(pageLayout.lower, [pageLayout.upper, pageLayout.lower]);
        break;

      //  inner sections
      case pageLayout.left.id:
        restoreInnerSection(pageLayout.left, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;
      case pageLayout.center.id:
        restoreInnerSection(pageLayout.center, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;
      case pageLayout.right.id:
        restoreInnerSection(pageLayout.right, [pageLayout.left, pageLayout.center, pageLayout.right]);
        break;

      //  inner right sections
      case pageLayout.rightUpper.id:
        restoreInnerSection(pageLayout.rightUpper, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightMiddle.id:
        restoreInnerSection(pageLayout.rightMiddle, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightLowerUpper.id:
        restoreInnerSection(pageLayout.rightLowerUpper, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
      case pageLayout.rightLower.id:
        restoreInnerSection(pageLayout.rightLower, [
          pageLayout.rightUpper,
          pageLayout.rightMiddle,
          pageLayout.rightLowerUpper,
          pageLayout.rightLower,
        ]);
        break;
    }
  }

  function collapseSection(collapseSection, layoutDefinition) {
    const collapseRef = collapseSection.ref.current;
    const collapseIndex = layoutDefinition.findIndex((def) => def.id === collapseSection.id);
    let definitionToGrow = layoutDefinition.filter(
      (def, idx) => idx > collapseIndex && !def.ref.current.isCollapsed(),
    )[0];

    if (!definitionToGrow) {
      // no sections under element to grow, take the first previous element
      definitionToGrow = layoutDefinition.filter((def, idx) => idx < collapseIndex && !def.ref.current.isCollapsed());
      if (definitionToGrow.length) {
        definitionToGrow = definitionToGrow[definitionToGrow.length - 1];
      } else {
        definitionToGrow = null;
      }
    }

    // try to expand upwards
    let space = collapseRef.getSize();
    const growRef = definitionToGrow?.ref.current;

    const immutableRefIdList = [collapseRef.getId(), growRef.getId()];

    const definitionVerification = layoutDefinition
      .filter((def) => !immutableRefIdList.includes(def.ref.current.getId()))
      .map((def) => {
        def.prevSize = def.ref.current.getSize();
        def.prevCollapsed = def.ref.current.isCollapsed(); // we may need this in future to verify the state of the view
        return def;
      });

    if (!growRef) {
      // use default functionality of library
      collapseRef.collapse();
      return;
    }

    const nextSize = space + growRef.getSize();
    collapseRef.collapse();
    growRef.resize(nextSize);

    // now fix all the other elements
    definitionVerification.forEach((def) => {
      if (def.prevCollapsed) {
        def.ref.current.collapse();
      } else {
        def.ref.current.resize(def.prevSize);
      }
    });
  }

  function restoreSection(restoreSection, sections) {
    // we only have two sections here so we can effectively reset the layout when either is section is restored
    sections.forEach((section) => {
      section.ref.current.collapse();
      section.ref.current.resize(minSizesInRatios[section.id]);
    });
  }

  function restoreInnerSection(restoreSection, sections) {
    // take from other sections while respecting min width

    const expandedSections = sections
      .filter((section) => !section.ref.current.isCollapsed())
      .map((section) => {
        section.initialSize = section.ref.current.getSize();
        return section;
      });

    if (expandedSections.length === 0) {
      // nothing to consider, just open the panel
      restoreSection.ref.current.resize(minSizesInRatios[restoreSection.id]);
    } else {
      // all of the below are ratio calculations
      const requiredRatio = restoreSection.defaultSize;

      const totalAvailableSize = expandedSections.reduce((acc, section) => {
        return acc + (section.ref.current.getSize() - minSizesInRatios[section.id]);
      }, 0);

      // open requested section
      restoreSection.ref.current.resize(requiredRatio);

      // collapse all sections
      sections.forEach((section) => section.id !== restoreSection.id && section.ref.current.collapse());

      // open expanded sections according to available space
      expandedSections.forEach((section) => {
        const currentSize = section.initialSize;
        const minSize = minSizesInRatios[section.id];

        const proportionToGive = (currentSize - minSize) / totalAvailableSize;
        const spaceToGive = proportionToGive * requiredRatio;

        const nextSize = Math.max(currentSize - spaceToGive, minSize);

        section.ref.current.resize(nextSize);
      });
    }
  }

  // set initial state after page has loaded
  React.useLayoutEffect(() => {
    setDisabled({
      lower: lowerPanelRef.current.isCollapsed() || upperPanelRef.current.isCollapsed(),
      left: leftPanelRef.current.isCollapsed() || centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed() || centerPanelRef.current.isCollapsed(),
      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightMiddle: false, // rightMiddlePanelRef.current.isCollapsed() || rightMiddlePanelRef.current.isCollapsed(),
      rightLowerUpper: false, // rightMiddlePanelRef.current.isCollapsed() || rightMiddlePanelRef.current.isCollapsed(),
      rightLower: false, // rightMiddlePanelRef.current.isCollapsed() || rightMiddlePanelRef.current.isCollapsed(),
    });

    setCollapsed({
      upper: upperPanelRef.current.isCollapsed(),
      lower: lowerPanelRef.current.isCollapsed(),

      left: leftPanelRef.current.isCollapsed(),
      center: centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed(),

      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightMiddle: rightMiddlePanelRef.current.isCollapsed(),
      rightLowerUpper: rightLowerUpperPanelRef.current.isCollapsed(),
      rightLower: rightLowerPanelRef.current.isCollapsed(),
    });
  }, []);

  function resetPanels() {
    const mainRefs = [upperPanelRef.current, lowerPanelRef.current];
    const innerRefs = [leftPanelRef.current, centerPanelRef.current, rightPanelRef.current];
    const innerRightRefs = [
      rightUpperPanelRef.current,
      rightMiddlePanelRef.current,
      rightLowerUpperPanelRef.current,
      rightLowerPanelRef.current,
    ];

    // in case it matters, start with innermost panels and work outwards
    if (innerRightRefs.every((ref) => !!ref)) {
      innerRightRefs[0].resize(Math.max(pageLayout.rightUpper.defaultSize, minSizesInRatios[pageLayout.rightUpper.id]));
      innerRightRefs[1].resize(
        Math.max(pageLayout.rightMiddle.defaultSize, minSizesInRatios[pageLayout.rightMiddle.id]),
      );
      innerRightRefs[2].resize(Math.max(pageLayout.rightLower.defaultSize, minSizesInRatios[pageLayout.rightLower.id]));
    }

    if (innerRefs.every((ref) => !!ref)) {
      const innerSections = [pageLayout.left, pageLayout.center, pageLayout.right];
      const totalRatioInner = innerSections.reduce(
        (accumulator, currentValue) => accumulator + minSizesInRatios[currentValue.id],
        0,
      );

      innerSections.forEach((section) =>
        section.ref.current.resize(totalRatioInner >= 90 ? minSizesInRatios[section.id] : section.defaultSize),
      );
    }

    if (mainRefs.every((ref) => !!ref)) {
      mainRefs[0].resize(Math.max(pageLayout.upper.defaultSize, minSizesInRatios[pageLayout.upper.id]));
      mainRefs[1].resize(Math.max(pageLayout.lower.defaultSize, minSizesInRatios[pageLayout.lower.id]));
    }

    setResizing(false);
    setDisabled({
      lower: false,
      left: false,
      right: false,
      rightUpper: false,
      rightLower: false,
    });
    setCollapsed({
      upper: false,
      lower: false,

      left: false,
      center: false,
      right: false,

      rightUpper: false,
      rightMiddle: false,
      rightLowerUpper: false,
      rightLower: false,
    });
  }

  function updateCollapsed() {
    setCollapsed({
      upper: upperPanelRef.current.isCollapsed(),
      lower: lowerPanelRef.current.isCollapsed(),

      left: leftPanelRef.current.isCollapsed(),
      center: centerPanelRef.current.isCollapsed(),
      right: rightPanelRef.current.isCollapsed(),

      rightUpper: rightUpperPanelRef.current.isCollapsed(),
      rightMiddle: rightMiddlePanelRef.current.isCollapsed(),
      rightLowerUpper: rightLowerUpperPanelRef.current.isCollapsed(),
      rightLower: rightLowerPanelRef.current.isCollapsed(),
    });
  }

  function updateHandleDisables() {
    const upper = upperPanelRef.current.isCollapsed();
    const lower = lowerPanelRef.current.isCollapsed();

    const left = leftPanelRef.current.isCollapsed();
    const center = centerPanelRef.current.isCollapsed();
    const right = rightPanelRef.current.isCollapsed();

    const rightUpper = rightUpperPanelRef.current.isCollapsed();
    const rightMiddle = rightMiddlePanelRef.current.isCollapsed();
    const rightLowerUpper = rightLowerUpperPanelRef.current.isCollapsed();
    const rightLower = rightLowerPanelRef.current.isCollapsed();

    //  main panel set
    setDisabled((prev) => ({
      ...prev,
      lower: !upper && !lower ? false : true,
    }));

    //  inner panel set
    if (!left && !center && !right) {
      // none collapsed
      setDisabled((prev) => ({
        ...prev,
        left: false,
        right: false,
      }));
    } else if (center || (left && right)) {
      // if center or both edge panels collapsed
      setDisabled((prev) => ({
        ...prev,
        left: true,
        right: true,
      }));
    } else {
      setDisabled((prev) => ({
        ...prev,
        left: left ? true : false,
        right: right ? true : false,
      }));
    }

    // inner right handles
    setDisabled((prev) => ({
      ...prev,
      rightUpper: rightUpper || rightMiddle,
      rightLowerUpper: rightLowerUpper || (rightMiddle && rightUpper),
      rightLower: rightLower || (rightLowerUpper && rightMiddle && rightUpper),
    }));
  }

  return (
    <PanelGroup direction="vertical" autoSaveId="main-group" id="main-group">
      <Panel
        id={pageLayout.upper.id}
        defaultSize={pageLayout.upper.defaultSize}
        minSize={minSizesInRatios.upper}
        ref={pageLayout.upper.ref}
        collapsible={upperPanelRef.current?.isCollapsed() || !isResizing}
      >
        <PanelGroup direction="horizontal" autoSaveId="upper-group" id="upper-group">
          <Panel
            id={pageLayout.left.id}
            defaultSize={pageLayout.left.defaultSize}
            minSize={minSizesInRatios.left}
            ref={pageLayout.left.ref}
            className={pageLayout.left.className}
            collapsible={leftPanelRef.current?.isCollapsed() || !isResizing}
          >
            {children[0]}
          </Panel>
          <PanelResizeHandle
            id="left-resize"
            className="scheduler-layout__resize-handle"
            onDragging={(isDragging) => setResizing(isDragging)}
            disabled={disabledHandles.left}
            hitAreaMargins={{ coarse: 0, fine: 0 }}
          >
            <PanelResizeButton
              sectionBefore={{
                id: pageLayout.left.id,
                label: pageLayout.left.sectionLabel,
                isCollapsed: collapsedPanels.left,
              }}
              sectionAfter={{
                id: pageLayout.center.id,
                label: pageLayout.center.sectionLabel,
                isCollapsed: collapsedPanels.center,
              }}
              altId={pageLayout.right.id}
              clickHandler={clickHandler}
              mode={
                !collapsedPanels.left && collapsedPanels.center ? (!collapsedPanels.right ? "alt" : "after") : "before"
              }
            />
          </PanelResizeHandle>
          <Panel
            id={pageLayout.center.id}
            defaultSize={pageLayout.center.defaultSize}
            minSize={minSizesInRatios.center}
            ref={pageLayout.center.ref}
            className={pageLayout.center.className}
            collapsible={centerPanelRef.current?.isCollapsed() || !isResizing}
          >
            {children[1]}
          </Panel>
          <PanelResizeHandle
            id="right-resize"
            className={`scheduler-layout__resize-handle ${collapsedPanels.center && !collapsedPanels.right && !collapsedPanels.left ? "scheduler-layout__resize-handle--hidden" : ""}`}
            onDragging={(isDragging) => setResizing(isDragging)}
            disabled={disabledHandles.right}
            hitAreaMargins={{ coarse: 0, fine: 0 }}
          >
            <PanelResizeButton
              sectionBefore={{
                id: pageLayout.center.id,
                label: pageLayout.center.sectionLabel,
                isCollapsed: collapsedPanels.center,
              }}
              sectionAfter={{
                id: pageLayout.right.id,
                label: pageLayout.right.sectionLabel,
                isCollapsed: collapsedPanels.right,
              }}
              clickHandler={clickHandler}
              mode={collapsedPanels.center && !collapsedPanels.right && collapsedPanels.left ? "before" : "after"}
            />
          </PanelResizeHandle>
          <Panel
            id={pageLayout.right.id}
            defaultSize={pageLayout.right.defaultSize}
            minSize={minSizesInRatios.right}
            ref={pageLayout.right.ref}
            className={pageLayout.right.className}
            collapsible={rightPanelRef.current?.isCollapsed() || !isResizing}
          >
            <PanelGroup direction="vertical" autoSaveId="right-group" id="right-group">
              {[pageLayout.rightUpper, pageLayout.rightMiddle, pageLayout.rightLowerUpper, pageLayout.rightLower].map(
                (layoutDefinition, index, list) => (
                  <React.Fragment key={layoutDefinition.id}>
                    <Panel
                      id={pageLayout[layoutDefinition.id].id}
                      className={pageLayout[layoutDefinition.id].className}
                      defaultSize={pageLayout[layoutDefinition.id].defaultSize}
                      minSize={minSizesInRatios[layoutDefinition.id]}
                      ref={layoutDefinition.ref}
                      collapsible={layoutDefinition.ref.current?.isCollapsed() || !isResizing}
                    >
                      {layoutDefinition.children}
                    </Panel>
                    <PanelResizeHandle
                      id={`${layoutDefinition.id}-resize`}
                      className={`scheduler-layout__resize-handle scheduler-layout__resize-handle--horizontal ${index + 1 === list.length ? "scheduler-layout__resize-handle--no-pad" : ""}`}
                      onDragging={(isDragging) => setResizing(isDragging)}
                      disabled={disabledHandles[layoutDefinition.id]}
                      hitAreaMargins={{ coarse: 0, fine: 0 }}
                    >
                      <PanelResizeButton
                        sectionBefore={{
                          id: layoutDefinition.id,
                          label: layoutDefinition.sectionLabel,
                          isCollapsed: collapsedPanels[layoutDefinition.id],
                        }}
                        sectionAfter={
                          list[index + 1]
                            ? {
                                id: list[index + 1].id,
                                label: layoutDefinition.sectionLabel,
                                isCollapsed: collapsedPanels[list[index + 1].id],
                              }
                            : {
                                id: layoutDefinition.id,
                                label: layoutDefinition.sectionLabel,
                                isCollapsed: collapsedPanels[layoutDefinition.id],
                              }
                        }
                        clickHandler={clickHandler}
                        mode={"before"}
                        onlyLabel={index + 1 === list.length}
                      />
                    </PanelResizeHandle>
                  </React.Fragment>
                ),
              )}
            </PanelGroup>
          </Panel>
        </PanelGroup>
      </Panel>
      <PanelResizeHandle
        id="bottom-resize"
        className={`scheduler-layout__resize-handle scheduler-layout__resize-handle--horizontal ${collapsedPanels.lower ? "scheduler-layout__resize-handle--collapsed" : ""}`}
        onDragging={(isDragging) => setResizing(isDragging)}
        disabled={disabledHandles.lower}
        hitAreaMargins={{ coarse: 0, fine: 0 }}
      >
        <PanelResizeButton
          sectionBefore={{
            id: pageLayout.upper.id,
            label: pageLayout.upper.sectionLabel,
            isCollapsed: collapsedPanels.upper,
          }}
          sectionAfter={{
            id: pageLayout.lower.id,
            label: pageLayout.lower.sectionLabel,
            isCollapsed: collapsedPanels.lower,
          }}
          clickHandler={clickHandler}
          mode={collapsedPanels.upper ? "before" : "after"}
        />
      </PanelResizeHandle>
      <Panel
        id={pageLayout.lower.id}
        defaultSize={pageLayout.lower.defaultSize}
        minSize={minSizesInRatios.lower}
        ref={pageLayout.lower.ref}
        className={pageLayout.lower.className}
        collapsible={lowerPanelRef.current?.isCollapsed() || !isResizing}
      >
        {pageLayout.lower.children}
      </Panel>
    </PanelGroup>
  );
});
ResizableLayout.displayName = "ResizableLayout";

export default ResizableLayout;
